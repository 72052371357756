import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div>
        <Link to="/">
          <span style={{ fontFamily: "Playfair Display", fontWeight: 400 }}>
            <img src="/logo192.png" width="24" alt="Claverton 42" />
          </span>
        </Link>
        <br />
        <br />
        <Link to="/startups">STARTUPS</Link>
        <br />
        <Link to="/smes">SMEs</Link>
        <br />
        <Link to="/corporations">CORPORATIONS</Link>
        <br />
        <br />
        <span style={{ fontSize: "0.8rem" }}>
          <Link to="/privacy">Privacy Notice</Link>
        </span>
        <br />
        <span style={{ fontSize: "0.8rem" }}>
          <Link to="/cookies">Cookie Policy</Link>
        </span>
      </div>
      <div>
        <br />
        <br />
        <Link to="/reports">REPORTS</Link>
        <br />
        <Link to="/portfolio">PORTFOLIO</Link>
        <br />
        <Link to="/careers">CAREERS</Link>
        <br />
        <br />
        <span style={{ fontSize: "0.8rem" }}>
          <a href="mailto:info@claverton42.com">info@claverton42.com</a>
        </span>
        <br />
        <span style={{ fontSize: "0.8rem" }}>&copy;2024 Claverton42</span>
      </div>
    </footer>
  );
}

export default Footer;
